import isNumber from '../helpers/functions/isNumber';

function fetchWithTimeout(url, timeout = 5000) {
  return new Promise((resolve, reject) => {
    // Set timeout timer
    const timer = setTimeout(
      () => reject(new Error('Request timed out')),
      timeout
    );

    fetch(url)
      .then(response => {
        if (!response.ok) {
          reject(new Error('Unsuccessful response'));
        }
        return response.json().then(result => {
          if (result.payload) {
            resolve(result.payload);
          }
          reject(new Error('There are no payload in response'));
        });
      })
      .catch(error => reject(new Error(error)))
      .finally(() => clearTimeout(timer));
  });
}

export function getAssetCart(ids = []) {
  let isValidIds = true;
  ids.forEach(id => {
    if (!isNumber(id)) {
      isValidIds = false;
    }
  });
  if (isValidIds) {
    return fetchWithTimeout(
      `${process.env.REACT_APP_API_URL}/assets/${ids.join()}`
    )
      .then(payload => ({ payload, error: null }))
      .catch(error => ({ payload: [], error: error.toString() }));
  }
  return Promise.resolve({ payload: [], error: 'Error: Category ids are not numbers' });
}

export function getCategories() {
  return fetchWithTimeout(`${process.env.REACT_APP_API_URL}/categories`)
    .then(payload => ({ payload, error: null }))
    .catch(error => ({ payload: [], error: error.toString() }));
}

export function getLatestAssets() {
  return fetchWithTimeout(`${process.env.REACT_APP_API_URL}/assets/latest`)
    .then(payload => ({ payload, error: null }))
    .catch(error => ({ payload: [], error: error.toString() }));
}

export function getCategory(id = null) {
  if (isNumber(id)) {
    return fetchWithTimeout(`${process.env.REACT_APP_API_URL}/categories/${id}`)
      .then(payload => ({ payload, error: null }))
      .catch(error => ({ payload: [], error: error.toString() }));
  }
  return Promise.resolve({ payload: [], error: 'Error: Category id is not a number' });
}
